import DogJoke from "../components/DogJoke";

const DogJokePage = () => {

    return (
        <>
            <section className="inner-container">
                <DogJoke/>
            </section>
        </>
    );
}

export default DogJokePage;
