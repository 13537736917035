import SiteNav from './SiteNav';

const Header = () => {

    return (
        <SiteNav />
    );
}

export default Header;
