const AboutPage = () => {

    return (
        <>
            <section className="inner-container">
                <h2 className="page-title">About DadJokez</h2>
                <div>
                    <p>This site is made just for a joke. It pulls jokes from the icanhazdadjoke.com API.</p>
                    <p>It was a fun way to get a practical use for React as I do not use it day-to-day.</p>
                </div>
            </section>
        </>
    );
}

export default AboutPage;
