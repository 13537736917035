import DadJoke from '../components/DadJoke'

const HomePage = () => {

    return (
        <>
            <section className="inner-container">
                <DadJoke />
            </section>
        </>
    );
}

export default HomePage;
